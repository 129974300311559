@use '../../../scss/' as *;

.team-box {
    @include transition3;
    position: relative;
    padding: 0px 0 20px 0;
    background: $bg-4;
    overflow: hidden;
    &::after {
        @include transition3;
        position: absolute;
        content: "";
        border: 1px solid rgba(34, 183, 143, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &::before {
        @include transition5;
        position: absolute;
        content: "";
        border: 2px solid rgba(34, 183, 143, 0);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &.active,
    &:hover {
        box-shadow: $box-shadow;
        &::before {
            border-color: #14C2A3;
            border: 2px solid #14C2A3;
        }
    }
    .image {
        min-height: 310px;
        z-index: -2;
        padding: 2px;
        img {
            max-height: 310px;
            width: 100%;
            height: 310px;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        padding: 16px 20px 0 20px;
        .h8 {
            margin-bottom: 5px;
            line-height: 1.4;
            font-weight: 400;
            text-transform: uppercase;
            font-family: "cybox", sans-serif;
        }
        p {
            margin-bottom: 19px;
            font-size: 14px;
            line-height: 1.57;
            text-transform: capitalize;
        }
    }
    .social {
        @include flex2;
        li {
            margin-right: 12px;
            a {
                @include flex(center,center);
                width: 28px;
                height: 28px;
                background: rgba(20,194,163,0.4);
                border-radius: 8px;
                color: #fff;
                font-size: 14px;
                &:hover {
                    background: rgba(20,194,163,1);;
                }
            }
        }
    }

}

.home-2 .team-box {
    margin-bottom: 30px;
}
.team-box .image img {
    max-height: 300px !important;
    width: 100%;
    height: auto !important;
    background: white;  
}

.side-b-team{
    display: flex;
    align-items: center;
    justify-content: center;
}